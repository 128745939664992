import {v1} from "uuid"

export const StateGlobal =
    [
        {
            id: v1(),
            name: "lampConcrete1",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },{
            id: v1(),
            name: "lampConcrete1",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },{
            id: v1(),
            name: "lampConcrete1",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },{
            id: v1(),
            name: "lampConcrete1",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },{
            id: v1(),
            name: "lampConcrete1",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete2",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete3",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete4",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete5",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete6",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete7",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete8",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "lampConcrete9",
            project: "light",
            price: "210",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "furnitureRack1",
            project: "furniture",
            price: "210",
            nameEN: "furniture",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "furnitureRack2",
            project: "furniture",
            price: "210",
            login3d: "lamp",
            nameEN: "furniture",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "furnitureRack3",
            project: "furniture",
            price: "210",
            login3d: "lamp",
            nameEN: "furniture",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "furnitureRack4",
            project: "furniture",
            price: "210",
            login3d: "lamp",
            nameEN: "furniture",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: v1(),
            name: "furnitureRack5",
            project: "furniture",
            price: "210",
            login3d: "lamp",
            nameEN: "furniture",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            imagesCounter: 1,
            images: [
                "lightConcreteRender1.png", "lightConcreteRender2.png", "lightConcreteRender3.png", "lightConcreteRender4.png"
            ],
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },

    ]
