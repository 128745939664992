import {v1} from "uuid"

export const StateFurniture =
    [
        {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        }, {
            id: 0,
            price: "210",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            path3D: 0,
            login3d: "lamp",
            nameEN: "Concrete lamp",
            nameRS: "бетонска лампа",
            nameRU: "Светильник бетонный",
            foto: "./fotos/furnitureRender2.png",
            render: "./fotos/furnitureRender1.png",
            check: true,
            components: [
                {
                    id: v1(),
                    name: "Vendor code LC01",
                    check: true,
                    elems: ["lightConcrete1elem1", "lightConcrete1elem2", "lightConcrete1elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC02",
                    check: false,
                    elems: ["lightConcrete2elem1", "lightConcrete2elem2", "lightConcrete2elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC03",
                    check: false,
                    elems: ["lightConcrete3elem1", "lightConcrete3elem2", "lightConcrete3elem3"]
                },
                {
                    id: v1(),
                    name: "Vendor code LC04",
                    check: false,
                    elems: ["lightConcrete4elem1", "lightConcrete4elem2"]
                },
            ]
        },
        {
            id: 1,
            price: "250",
            descriptionEN: "",
            descriptionRS: "",
            descriptionRU: "desctiption RU",
            login3d: "lampwood",
            pass3d: "demo",
            nameEN: "LAMP WOOD",
            nameRS: "ПОЛИЦА",
            nameRU: "ЛАМПА ИЗ БРУСА",
            foto: "./fotos/lamp1foto.png",
            render: "./fotos/lamp2render.png",
            check: true,
            components: [
                {id: v1(), groupElements: 0, name: "FoxChrome", file: "poligonFoxChrome", check: true},
                {id: v1(), groupElements: 0, name: "FoxGold", file: "poligonFoxGold", check: false},
                {id: v1(), groupElements: 0, name: "FoxBlack", file: "poligonFoxBlack", check: false},
            ]
        }
    ]
